<template>
    <div id="app">
        <redoc-wrapper
            :spec-or-spec-url="json"
            :options="redocOptions"
        ></redoc-wrapper>
    </div>
</template>

<script>
import RedocWrapper from '@hnluu8/vue-redoc-wrapper';

export default {
    name: 'app',
    components: {
        RedocWrapper
    },
    data() {
        return {
            // https://github.com/Redocly/redoc#redoc-options-object
            redocOptions: {
                hideDownloadButton: false
            },
            json: 'recuperi.json'
        };
    }
};
</script>
